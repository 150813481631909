import React from 'react';
import logo from './logo.png';
import './App.css';

import Areas from './Areas'
import EA from './EA'

function App() {
  return (
    <>
    <div className="App-home">
    <div className="App">
      <img src={logo} className="App-logo" alt="logo" />
      <header className="App-header">
        <h3>
          Advocates, Commissioner for Oaths, Notary Public, Advisors, Researchers and Legal Consultants
        </h3>
      </header>
    </div>
    </div>
    <Areas/>
    <EA/>
    <footer>
      <div className="container">
        <br/>
        Call us on <b>+254 (0) 756 240 340</b>
        <br/>
        Email: <a href="mailto:info@ealawconsulting.com">info@ealawconsulting.com</a>
        <br/>
        <br/>
        PO Box 4681 -00100 Nairobi Kenya
        <br/>
        <small>Copy right 2020. All rights reserved.</small>
      </div>
    </footer>
    </>
  );
}

export default App;
