import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
        <h3>
          Areas of Practice
        </h3>
        <p>
            Legislative Drafting<br/>
            Alternative Dispute Resolution<br/>
            Commercial Law-General<br/>
            Constitutional and Human Rights Law<br/>
            Pro bono Legal Service<br/>
            Criminal Law-General<br/>
            Regional & International Law<br/>
            Property, Conveyancing, Land, Landlord & Tenant Law<br/>
            Family Law & Succession Matters<br/>
            General Practice<br/>
            Others
        </p>
    </div>
  );
}

export default App;
