import React from 'react';

function App() {
  return (
    <div className="App">
        <h3>
        Ekuru Aukot PhD., LLM, LLB, Dip (Legal Practice)<br/>
        <small>Team Leader and Founder</small>
        </h3>
        <div className="article">
        <p>
            <b>Conviction.</b> Ekuru Aukot hails from the “Kenya of the North”, the now, “oily” Turkana County of the former Northern Frontier Districts. He is the Founder of Thirdway Alliance Kenya (www.thirdwayalliance.org), initially founded as a political movement towards alternative & transformative leadership in Kenya, now a fully registered Political Party. Ekuru believes that the African continent is endowed with abundant opportunities that could accelerate prosperity and development, which is being undermined by a frail and feckless leadership that has failed to optimally exploit Africa’s abundant potential. He was the Thirdway Alliance Kenya’s Presidential candidate (www.ekurunzai.com) in the 2017 Elections in Kenya in order to re-write
the story of Kenya, at least, for future generations. He believes that past mistakes, especially of bad leadership can only be corrected by a generation that desires to have a better country, and ultimately a much-desired Nation state. A new narrative must be written; one that must bring us back our country. 
</p>
<p>
    <b>Constitution building & Beyond Kenya Professional Experience:</b>
 From January 2014 to May 2016, he was UNDP’s Chief Technical & Senior Constitutional Advisor to the Liberian Constitution Review Committee (CRC). As the Expert on Constitutional Processes (February- April 2016), he assisted The Mountain Kingdom of Lesotho to develop a roadmap for its constitution-making process. He successfully directed as its Director/CEO & Member, the writing of Kenya’s 2010 Constitution by the Committee of Experts (CoE). Since 2010, he travelled to speak on Kenyan Constitution building process in Egypt, Tunisia, South Sudan, Zimbabwe, Zambia, Ukraine, Germany, the Hague, at the Jackson Institute for Global Affairs (Yale), Cornell School of Law, New York, and at Trinity College, Connecticut, USA. Senior Advisor to Governments. He consulted for two United States-based Corporations, the Universal Strategy Group Inc., (USGI) & the Development Alternatives Inc., (DAI) sub-contracted by the US Department of State, as Senior Advisor on Policy & Planning at the Ministry of Defence, the Republic of South Sudan (2010-2011). In addition, he was a Commissioner & member of the High Level Independent Commission, the Transitional Federal Government of Somalia. Endorsement. In 2011, following a Parliamentary approval, Ekuru was appointed by the then President, and Prime Minister of Kenya, respectively to Chair the Selection Panel that recruited the 1st Independent Electoral & Boundaries Commission (IEBC) of Kenya under the New Constitution of Kenya, 2010;
</p>
<p>
    <b>Scholarly & expertise experience.</b>
 He taught Constitutional & Administrative Law at the School of Law (2000-2005), University of Warwick (UK), and at Kenya School of Law (2006-2009); has been a Visiting Law Lecturer on the Law of Refugees & IDPs at School & Faculty of Law, University of Nairobi and at the East African School on Refugees & Humanitarian Affairs, and at Refugee Law Project, Makerere University, Uganda; he edited (2008-2010) the African Section of the Refugee Law Reader. Has expertise in constitutional law, Constitution Building & Implementation processes, policy, International & Human rights law, governance and Forced Migration. He is writing his memoirs, “A Constitution is Not a Shopping List” based on Constitution-building and implementation process.
</p>
<p>
<b>Education & Legal work.</b> He is a Chevening Alumni (1999-2000). He studied for PhD and LLM in International Refugee Law, & Law in Development respectively at the University of Warwick, UK; LLB (University of Nairobi) and Dip., in Legal Practice (Kenya School of Law); he is an Advocate of the High Court of Kenya, a Commissioner for Oaths & Notary Public; and the Founder & Team Leader at EA Law Consulting (www.ealawconsulting.com). 
</p>
<p>
<b>Concern for people.</b> He is the Founder & President of Ekuru Aukot Foundation, which supports education of nomadic and pastoralists boys and girls.
</p>
        </div>
    </div>
  );
}

export default App;
